//const stickynavbar = (() => {
//    let navbar = $('.navbar-sticky');
//    if (navbar == null || navbar == undefined) return;
//    let navbarClass = navbar[0].classList,
//        navbarH = navbar[0].offsetHeight,
//        scrollOffset = 500;
//    if (navbarClass.contains('position-absolute')) {
//        window.addEventListener('scroll', (e) => {
//            if (e.currentTarget != null) {
//                if ((e.currentTarget as Window).pageYOffset > scrollOffset) {
//                    navbar.addClass('navbar-stuck');
//                } else {
//                    navbar.removeClass('navbar-stuck');
//                }
//            }
//        });
//    } else {
//        window.addEventListener('scroll', (e) => {
//            if (e.currentTarget != null) {
//                if ((e.currentTarget as Window).pageYOffset > scrollOffset) {
//                    document.body.style.paddingTop = navbarH + 'px';
//                    navbar.addClass('navbar-stuck');
//                } else {
//                    document.body.style.paddingTop = '';
//                    navbar.removeClass('navbar-stuck');
//                }
//            }
//        });
//    }
//})();
//export default stickynavbar;
export class stickynavbar {
    static init() {
        let navbar = $('.navbar-sticky');
        if (navbar == null || navbar == undefined || navbar.length == 0)
            return;
        let navbarClass = navbar[0].classList, navbarH = navbar[0].offsetHeight, scrollOffset = 500;
        if (navbarClass.contains('position-absolute')) {
            window.addEventListener('scroll', (e) => {
                if (e.currentTarget != null) {
                    if (e.currentTarget.pageYOffset > scrollOffset) {
                        navbar.addClass('navbar-stuck');
                    }
                    else {
                        navbar.removeClass('navbar-stuck');
                    }
                }
            });
        }
        else {
            window.addEventListener('scroll', (e) => {
                if (e.currentTarget != null) {
                    if (e.currentTarget.pageYOffset > scrollOffset) {
                        document.body.style.paddingTop = navbarH + 'px';
                        navbar.addClass('navbar-stuck');
                    }
                    else {
                        document.body.style.paddingTop = '';
                        navbar.removeClass('navbar-stuck');
                    }
                }
            });
        }
    }
}
