import * as noUiSlider from 'nouislider';
//import * as noUiSlider from 'node_modules/nouislider/dist/nouislider'
export class rangeslider {
    static init() {
        let rangeSliderWidget = document.querySelectorAll('.range-slider');
        for (let i = 0; i < rangeSliderWidget.length; i++) {
            let rangeSlider = rangeSliderWidget[i].querySelector('.range-slider-ui'), valueMinInput = rangeSliderWidget[i].querySelector('.range-slider-value-min'), valueMaxInput = rangeSliderWidget[i].querySelector('.range-slider-value-max');
            let options = {
                dataStartMin: parseInt(rangeSliderWidget[i].dataset.startMin, 10),
                dataStartMax: parseInt(rangeSliderWidget[i].dataset.startMax, 10),
                dataMin: parseInt(rangeSliderWidget[i].dataset.min, 10),
                dataMax: parseInt(rangeSliderWidget[i].dataset.max, 10),
                dataStep: parseInt(rangeSliderWidget[i].dataset.step, 10),
                dataPips: rangeSliderWidget[i].dataset.pips
            };
            let start = (options.dataStartMax) ? [options.dataStartMin, options.dataStartMax] : [options.dataStartMin], connect = (options.dataStartMax) ? true : 'lower';
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let sliderPips = (options.dataPips ? { mode: 'count', values: 5 } : false);
            if (rangeSlider != null) {
                let sliderApi = noUiSlider.create(rangeSlider, {
                    start: start,
                    connect: connect,
                    step: options.dataStep,
                    pips: sliderPips,
                    range: {
                        'min': options.dataMin,
                        'max': options.dataMax
                    },
                    format: {
                        to: function (value) {
                            return parseInt(String(value), 10);
                        },
                        from: function (value) {
                            return Number(value);
                        }
                    },
                    tooltips: true
                });
                sliderApi.on('update', (values, handle) => {
                    let value = String(values[handle]);
                    value = value.replace(/\D/g, '');
                    if (handle) {
                        if (valueMaxInput) {
                            valueMaxInput.value = String(Math.round(Number(value)));
                        }
                    }
                    else {
                        if (valueMinInput) {
                            valueMinInput.value = String(Math.round(Number(value)));
                        }
                    }
                });
                if (valueMinInput) {
                    valueMinInput.addEventListener('change', function () {
                        sliderApi.set([this.value, ""]);
                    });
                }
                if (valueMaxInput) {
                    valueMaxInput.addEventListener('change', function () {
                        sliderApi.set(["", this.value]);
                    });
                }
            }
        }
    }
}
