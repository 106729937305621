import { Swiper, Pagination, Navigation } from 'swiper';
export class swipercarousel {
    static init() {
        // forEach function
        //let forEach = (array, callback, scope) => {
        //    for (let i = 0; i < array.length; i++) {
        //        callback.call(scope, i, array[i]); // passes back stuff we need
        //    }
        //};
        //// Carousel initialisation
        //let carousels = document.querySelectorAll('.swiper');
        //forEach(carousels, (index, value) => {
        //    let userOptions,
        //        pagerOptions;
        //    if (value.dataset.swiperOptions != undefined) userOptions = JSON.parse(value.dataset.swiperOptions);
        //    // Pager
        //    if (userOptions.pager) {
        //        pagerOptions = {
        //            pagination: {
        //                el: userOptions.pagination.el,
        //                clickable: true,
        //                bulletActiveClass: 'active',
        //                bulletClass: 'page-item',
        //                renderBullet: function (index, className) {
        //                    return '<li class="' + className + '"><a href="#" class="page-link btn-icon btn-sm">' + (index + 1) + '</a></li>';
        //                }
        //            }
        //        }
        //    }
        //    // Slider init
        //    let options = { ...userOptions, ...pagerOptions };
        //    let swiper = new Swiper(value, options);
        //    // Tabs (linked content)
        //    if (userOptions.tabs) {
        //        swiper.on('activeIndexChange', (e) => {
        //            let targetTab = (e.slides[e.activeIndex] as HTMLElement).dataset.swiperTab as unknown as HTMLElement | null;
        //            let previousTab = (e.slides[e.previousIndex] as HTMLElement).dataset.swiperTab as unknown as HTMLElement | null;
        //            previousTab?.classList.remove('active');
        //            targetTab?.classList.add('active');
        //        });
        //    }
        //}, null);
        let $carousels = $('.swiper');
        $carousels.each((index, element) => {
            let userOptions, pagerOptions;
            if (element.dataset.swiperOptions != undefined) {
                userOptions = JSON.parse(element.dataset.swiperOptions);
            }
            // Pager
            if (userOptions.pager) {
                pagerOptions = {
                    pagination: {
                        el: userOptions.pagination.el,
                        clickable: true,
                        bulletActiveClass: 'active',
                        bulletClass: 'page-item',
                        renderBullet: function (index, className) {
                            return '<li class="' + className + '"><a href="#" class="page-link btn-icon btn-xs fs-xs">' + (index + 1) + '</a></li>';
                        }
                    }
                };
            }
            // Slider init
            Swiper.use([Navigation, Pagination]);
            let options = { ...userOptions, ...pagerOptions };
            let swiper = new Swiper(element, options);
            //swiper.pagination.render();
        });
    }
}
