import SmoothScroll from 'smooth-scroll';
export class smoothscroll {
    static init() {
        let selector = '[data-scroll]', fixedHeader = '[data-scroll-header]', scroll = new SmoothScroll(selector, {
            speed: 800,
            speedAsDuration: true,
            offset: (anchor, toggle) => {
                return toggle.dataset.scrollOffset || 40;
            },
            header: fixedHeader,
            updateURL: false
        });
    }
}
